import {useState, type FC, useRef, useEffect} from 'react'
import classes from './ChatPage.module.scss'
import MyEditor from '../universal-components/my-editor/MyEditor'
import EditIcon from '../icons/chat/EditIcon'
import RightArrowIcon from '../icons/chat/RightArrowIcon'
import CopyIcon from '../icons/chat/CopyIcon'
import RefreshIcon from '../icons/chat/RefreshIcon'
import DeleteIcon from '../icons/chat/DeleteIcon'
import LeftArrowIcon from '../icons/chat/LeftArrowIcon'
import FormArea from './FormArea'
import {AnswerToEdit} from '../../../types/types'
import {useAuth} from '../../../app/modules/auth'
import CrossIcon from '../icons/chat/CrossIcon'
import {LimitNotification} from '../universal-components/limit-notification/LimitNotification'
import 'react-toastify/dist/ReactToastify.css'
import {Button, CloseButton} from 'react-bootstrap'
import {GiHamburgerMenu} from 'react-icons/gi'
import {Link} from 'react-router-dom'
import CloseIcon from '../icons/chat/CloseIcon'
import {useGetSettingQuery} from '../../../services/settingServiceApi'
import {isRunningOnKlevereDomain} from '../../../utils/white-label/permission'
interface ChatPageProps {}

interface IHistoryItem {
  id: number
  question: string
  answer: string
}

const preformedData = [
  {
    title: 'I have an e-commerce brand',
    description: 'give me the top PR companies in Berlin',
    departament: 'marketing',
  },
  {title: 'Summarize my', description: 'financial report for 2023', departament: 'finance'},
  {
    title: 'Give me ideas',
    description: 'for a new startup I can launch in Biotech',
    departament: 'marketing',
  },
  {
    title: 'Build me a content calendar',
    description: 'for social media over the next 30 days',
    departament: 'marketing',
  },
  {
    title: 'Looking to hire a Marketer in Tokyo',
    description: 'what salary range can I expect to pay',
    departament: 'hr',
  },
  {
    title: 'Analyze my financial forecast',
    description: 'and give me insights for improvement',
    departament: 'finance',
  },
  {
    title: 'Find me 50 manufacturing companies',
    description: 'I can approach for a proposal',
    departament: 'sales',
  },
  {title: 'Help me create', description: 'a HR policy for remote work', departament: 'hr'},
]

const ChatPage: FC<ChatPageProps> = () => {
  const chatClassNames = {
    alignLeft: 'chat__alignLeft',
    narrow: 'chat__narrow',
    hidden: undefined,
  }
  const {currentUser, toggleSidebar} = useAuth()
  const [history, setHistory] = useState<IHistoryItem[]>([])
  const [answerToEdit, setAnswerToEdit] = useState<AnswerToEdit>({id: 0, text: ''})
  const [chatClass, setChatClass] = useState<string | undefined>(undefined)
  const [triggerCopyEditor, setTriggerCopyEditor] = useState<number | string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [triggerRegenerate, setTriggerRegenerate] = useState<number | string>('')
  const [question, setQuestion] = useState<string>('')
  const [departament, setDepartament] = useState<string>('')
  const [logo, setLogo] = useState<string | null>(null)
  const [error, setError] = useState('')

  const {data: response, isLoading: getApiLoading} = useGetSettingQuery(undefined)

  useEffect(() => {
    if (!getApiLoading && response) {
      if (!response.success) return
      if (response.theme_type === 'GLOBAL') return
      if (response.data.large_logo) {
        setLogo(response.data.large_logo)
      }
    }
  }, [getApiLoading, response])

  const handleTriggerCopyEditor = () => {
    setTriggerCopyEditor(Date.now())
  }

  const handleCopyAnswer = async (dataToCopy: any) => {
    try {
      await navigator.clipboard.writeText(dataToCopy)
    } catch (err) {
      console.error('Unable to copy to clipboard', err)
    }
  }
  const handlePreformedAnswer = (departament: string, question: string) => {
    setQuestion(question)
    setDepartament(departament)
  }

  const copyToEditor = (id: number) => {
    const currentQuestion = history.find((item) => item.id === id)
    currentQuestion && setAnswerToEdit({id: id, text: `${currentQuestion?.answer} `})
    // handleChatClass(chatClassNames.alignLeft)
  }

  const deleteLastAnswer = () => {
    const newHistory = [...history]
    newHistory.pop()
    setHistory(newHistory)
  }

  const handleRegenerate = () => {
    setTriggerRegenerate(Date.now())
  }
  const sectionRef = useRef<HTMLDivElement | null>(null)
  const handleChatClass = (toClose: boolean = false) => {
    const targetSection = document.getElementById('scroll-target')

    let newClassName: string | undefined = chatClassNames.hidden
    if (!toClose) {
      switch (chatClass) {
        case chatClassNames.hidden:
          setAnswerToEdit({id: 0, text: ''})
          newClassName = chatClassNames.alignLeft
          break
        case chatClassNames.alignLeft:
          newClassName = chatClassNames.narrow
          break
        case chatClassNames.narrow:
          newClassName = chatClassNames.alignLeft
          break
        default:
          break
      }
    }
    setChatClass(newClassName)
    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: 'smooth',
        inline: 'start',
        block: 'nearest',
      })
    }
  }

  const historyBlock = history?.map((item: IHistoryItem, index: number) => {
    const {id, question, answer} = item
    const lastIndex = history.length - 1
    const prettyAnswer = answer.split('\n').map((unit, index) => <p key={index}>{unit}</p>)
    return (
      <div key={id} className={classes.chat__centralBlock__history__item}>
        <div className={classes.chat__centralBlock__history__item__question}>
          <div className={classes.chat__centralBlock__history__item__question__imgBlock}>
            <img src={`${currentUser?.avatar || '/new-design/image/avatar.png'}`} alt='user' />
          </div>
          <div className={classes.chat__centralBlock__history__item__question__text}>
            <span>{question}</span>
          </div>
        </div>
        <div className={classes.chat__centralBlock__history__item__answer}>
          <div className={classes.chat__centralBlock__history__item__answer__imgBlock}>
            <img src='/new-design/image/chat-logo.jpg' alt='logo' />
          </div>
          <div className={classes.chat__centralBlock__history__item__answer__text}>
            <div className={classes.chat__centralBlock__history__item__answer__text__prettyAnswer}>
              {prettyAnswer}
            </div>
            <div className={classes.chat__centralBlock__history__item__answer__text__btns}>
              <button
                title='Open editor'
                disabled={chatClass !== chatClassNames.hidden}
                onClick={() => handleChatClass()}
              >
                <EditIcon className={classes.svg} />
              </button>
              <button title='Copy to editor' onClick={() => copyToEditor(id)}>
                <RightArrowIcon className={classes.svg} />
              </button>
              <button
                title='Copy to clipboard'
                onClick={() => {
                  handleCopyAnswer(answer)
                }}
              >
                <CopyIcon className={classes.svg} />
              </button>
              {index === lastIndex && (
                <>
                  <button title='Regenerate' onClick={handleRegenerate} disabled={isLoading}>
                    <RefreshIcon className={classes.svg} />
                  </button>
                  <button title='Delete' onClick={deleteLastAnswer} disabled={isLoading}>
                    <DeleteIcon className={classes.svg} />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  })

  return (
    <div className={`universalPage ${classes.chat} ${chatClass ? classes[chatClass] : undefined}`}>
      {error.trim() !== '' ? <LimitNotification errorMessage={error} /> : null}
      {/* <div
        className={classes.chat__centralBlock}
        style={{
          // display: 'flex',
        }}
      > */}
      <div className={`d-flex align-items-center justify-content-between d-lg-none d-block`}>
        <div className={`${classes.chat__centralBlock__history__emptyHistory__imgBlock} `}>
          <Link to={'/'}>
            {isRunningOnKlevereDomain() ? (
              <img className='w-75' src={'/new-design/image/logo.png'} alt='logo' />
            ) : (
              <img className='w-75' src={response?.data?.large_logo} alt='logo' />
            )}
          </Link>
        </div>
        <Button
          variant='dark'
          className={'navbar-toggler-btn '}
          style={{
            marginLeft: 'auto',
          }}
          onClick={() => toggleSidebar()}
        >
          <GiHamburgerMenu />
        </Button>
      </div>
      {/* </div> */}
      <div className={`${classes.chat__centralBlock} mt-3 mt-md-0`}>
        <div className={classes.chat__centralBlock__history}>
          {historyBlock.length > 0 ? (
            historyBlock
          ) : (
            <div className={classes.chat__centralBlock__history__emptyHistory}>
              <div
                className={`${classes.chat__centralBlock__history__emptyHistory__imgBlock} d-lg-block d-none`}
              >
                {isRunningOnKlevereDomain() ? (
                  <img className='w-75' src={'/new-design/image/logo.png'} alt='logo' />
                ) : (
                  <img className='w-75' src={response?.data?.large_logo} alt='logo' />
                )}
              </div>

              <h2
                className={`${classes.chat__centralBlock__history__emptyHistory__title} mt-lg-0 mt-md-5 mt-0`}
              >
                How can I help you today?
              </h2>
              <div
                className={classes.chat__centralBlock__history__emptyHistory__preformed_requests}
              >
                <div className='container'>
                  <div className='row'>
                    {preformedData.map((item, index) => {
                      if (index % 2 === 0)
                        return (
                          <div key={`1-${index}`} className=' col-6 col-md-6 mb-4'>
                            <div
                              className={`${classes.chat__centralBlock__history__emptyHistory__preformed_requests__item} `}
                              onClick={() => {
                                handlePreformedAnswer(
                                  item.departament,
                                  `${item.title} ${item.description}`
                                )
                              }}
                            >
                              <h3
                                className={
                                  classes.chat__centralBlock__history__emptyHistory__preformed_requests__item__title
                                }
                              >
                                {item.title}
                              </h3>
                              <p
                                className={
                                  classes.chat__centralBlock__history__emptyHistory__preformed_requests__item__description
                                }
                              >
                                {item.description}
                              </p>
                            </div>
                          </div>
                        )
                    })}
                    {preformedData.map((item, index) => {
                      if (index % 2 !== 0)
                        return (
                          <div key={`2-${index}`} className=' col-6 col-md-6 mb-4'>
                            <div
                              className={`${classes.chat__centralBlock__history__emptyHistory__preformed_requests__item}`}
                              onClick={() => {
                                handlePreformedAnswer(
                                  item.departament,
                                  `${item.title} ${item.description}`
                                )
                              }}
                            >
                              <h3
                                className={
                                  classes.chat__centralBlock__history__emptyHistory__preformed_requests__item__title
                                }
                              >
                                {item.title}
                              </h3>
                              <p
                                className={
                                  classes.chat__centralBlock__history__emptyHistory__preformed_requests__item__description
                                }
                              >
                                {item.description}
                              </p>
                            </div>
                          </div>
                        )
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={classes.chat__centralBlock__editor} id={'scroll-target'}>
          <div className={classes.chat__centralBlock__editor__topBlock}>
            <div>
              <button onClick={() => handleChatClass()} title='Change width'>
                <LeftArrowIcon
                  className={`${classes.svg} ${
                    chatClass === chatClassNames.narrow ? classes.svgRevert : undefined
                  }`}
                />
              </button>
            </div>
            <div className={classes.chat__header__rightBlock}>
              <button onClick={handleTriggerCopyEditor} title='Copy to clipboard'>
                <CopyIcon className={classes.svg1} />
              </button>
              <button onClick={() => handleChatClass(true)} title='Close editor'>
                {/* <CrossIcon height='24' width='24' /> */}
                <CloseIcon height='24' width='24' className={classes.svg1} />

                {/* <CloseButton style={{ background: 'transparent' }} title='Close' onClick={() => handleChatClass(true)} /> */}
              </button>

              {/* <CloseButton style={{ background: 'transparent' }} title='Close' onClick={() => handleChatClass(true)} /> */}
            </div>
          </div>
          <MyEditor answerToEdit={answerToEdit} triggerCopy={triggerCopyEditor} />
        </div>
      </div>
      <FormArea
        history={history}
        setHistory={setHistory}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        triggerRegenerate={triggerRegenerate}
        question={question}
        departament={departament}
        setError={setError}
      />
    </div>
  )
}

export default ChatPage
