import {useEffect, useState, type FC} from 'react'
import {Dropdown, Form, InputGroup, Table} from 'react-bootstrap'
import {BsPen, BsPersonAdd, BsPersonLinesFill, BsThreeDotsVertical} from 'react-icons/bs'
import {useAuth} from '../../../app/modules/auth'
import {
  useChangeUserStatusMutation,
  useGetCompanyByIdQuery,
  useGetCompanyMutation,
} from '../../../services/companyServiceApi'
import {getUsersLimit} from '../../../utils/getUsersLimit'
import InviteColleague from '../homepage/InviteColleague'
import UniversalHiddenScreen from '../universal-components/hidden-screen/UniversalHiddenScreen'
import classes from './BrandVoice.module.scss'
import userClasses from './Users.module.scss'
import moment from 'moment'
import {MdDataUsage} from 'react-icons/md'
import BrandVoiceTrainingModal from './components/BrandVoiceTrainingModal'

import {
  useChangeStatusMutation,
  useDeleteBrandVoiceMutation,
  useGetBrandVoiceQuery,
} from '../../../services/brandVoiceServiceApi'

import ConfirmationModal from '../modal-page/ConfirmationModal'
import {toast} from 'react-toastify'
import {FaFilePen, FaPowerOff, FaRegPenToSquare} from 'react-icons/fa6'
import ExportIcon from '../icons/workflow/ExportIcon'
import {GlobalErrorMessagesEnum} from '../../../types/types'

interface BrandVoiceProps {}

enum ActionType {
  ACTIVE_BRAND_VOICE = 'ACTIVE_BRAND_VOICE',
  ADD_BRAND_VOICE = 'ADD_BRAND_VOICE',
  EDIT_BRAND_VOICE = 'EDIT_BRAND_VOICE',
  DELETE_BRAND_VOICE = 'DELETE_BRAND_VOICE',
}

const BrandVoice: FC<BrandVoiceProps> = () => {
  const {currentUser} = useAuth()
  const [changeStatusMutation] = useChangeStatusMutation()
  const [deleteBrandVoiceMutation] = useDeleteBrandVoiceMutation()
  const [visibleBackground, setVisibleBackground] = useState(false)
  const [showTrainingModal, setShowTrainingModal] = useState(false)
  const [page, setPage] = useState(1)
  const [currentId, setCurrentId] = useState<number | null>(null)
  const [actionType, setActionType] = useState<string | null>(null)
  const [confirmationMessage, setConfirmationMessage] = useState<string>('')
  const [keywords, setKeywords] = useState('')
  const [selectedBrandVoice, setSelectedBrandVoice] = useState<any>(null)

  const {
    data: brandVoices = {
      brandVoices: [
        {
          id: 0,
          name: 'Example',
          is_active: false,
          personality_traits: null,
          created_at: '',
        },
      ],
      hasMoreMembers: false,
    },
    refetch,
  } = useGetBrandVoiceQuery({page, keywords})

  const handleVisibility = () => {
    setVisibleBackground((prev) => !prev)
  }

  const handlePagination = (action: string) => {
    if (action === 'next') {
      setPage((prev) => prev + 1)
    } else {
      setPage((prev) => prev - 1)
    }
  }

  const handleActiveAction = (id: number) => {
    setCurrentId(id)
    setActionType(ActionType.ACTIVE_BRAND_VOICE)
    setConfirmationMessage('Are you sure you want to activate this brand voice?')
  }

  const handleAddAction = () => {
    setSelectedBrandVoice(null)
    setActionType(ActionType.ADD_BRAND_VOICE)
    setShowTrainingModal(true)
  }

  const handleEditAction = (id: number) => {
    setCurrentId(id)
    setActionType(ActionType.EDIT_BRAND_VOICE)
    setSelectedBrandVoice({
      ...brandVoices?.brandVoices?.find((item: any) => item.id === id),
      created_at: Date.now(),
    })
    setShowTrainingModal(true)
  }

  const handleDeleteAction = (id: number) => {
    setCurrentId(id)
    setActionType(ActionType.DELETE_BRAND_VOICE)
    setConfirmationMessage('Are you sure you want to delete this brand voice?')
  }

  const handleConfirm = () => {
    const brandVoice = brandVoices?.brandVoices?.find((item: any) => item.id === currentId)
    const personaName = brandVoice?.name.slice(0, 10)

    if (actionType === ActionType.ACTIVE_BRAND_VOICE) {
      const successMessage = `Brand voice "${personaName}" ${
        brandVoice.is_active ? 'deactivated' : 'activated'
      }`

      changeStatusMutation({id: currentId})
        .then(() => {
          setCurrentId(null)
        })
        .catch(() => {
          setCurrentId(null)
          toast.error(GlobalErrorMessagesEnum.SERVER_ERROR)
        })

        .finally(() => {
          refetch()
          toast.success(successMessage)
        })
    }

    if (actionType === ActionType.DELETE_BRAND_VOICE) {
      const successMessage = `Brand voice "${personaName}" deleted`
      deleteBrandVoiceMutation({id: currentId})
        .then(() => {
          setCurrentId(null)
        })
        .catch(() => {
          setCurrentId(null)
          toast.error(GlobalErrorMessagesEnum.SERVER_ERROR)
        })
        .finally(() => {
          refetch()
          toast.success(successMessage)
        })
    }
  }

  const handleCancel = () => {
    setCurrentId(null)
  }
  type JsonData = {
    [key: string]: {
      [key: string]: string
    }
  }

  const parseJsonAndGetTags = (jsonString: string): string => {
    try {
      const data: JsonData = JSON.parse(jsonString)
      const tags: Set<string> = new Set<string>()

      // Extract tags from each section
      Object.values(data).forEach((section) => {
        Object.values(section).forEach((value) => {
          // Split by commas and trim whitespace
          value.split(',').forEach((tag) => {
            const trimmedTag = tag.trim()
            if (trimmedTag) {
              tags.add(trimmedTag)
            }
          })
        })
      })

      // Convert Set to Array and limit to 10 tags
      return Array.from(tags).slice(0, 5).join(', ')
    } catch (error) {
      return '-'
    }
  }

  const tableRows = brandVoices?.brandVoices?.map((item: any = {}) => {
    const {id, name, is_active, personality_traits, created_at} = item

    return (
      <tr key={id}>
        <td  className={'align-middle'}>
          <span className='m-2'>{name}</span>
        </td>

        <td  className={'align-middle'}>{personality_traits}</td>
        <td  className={'align-middle'}>
          {is_active === true ? (
            <span className={classes.status_active}>Active</span>
          ) : (
            <span className={classes.status_inactive}>Inactive</span>
          )}
        </td>

        <td  className={'align-middle'}>
          <p>{moment(created_at).format('MM/DD/YYYY')}</p>
        </td>
        <td  className={'align-middle'}>
          <Dropdown>
            <Dropdown.Toggle
              className={classes.dropdownToggle}
              variant='primary'
              id='dropdown-basic'
            >
              <BsThreeDotsVertical />
            </Dropdown.Toggle>
            <Dropdown.Menu className={classes.dropdownMenu}>
              <>
                <Dropdown.Item
                  className={classes.drodownMenuButtons}
                  onClick={() => handleActiveAction(id)}
                >
                  <FaPowerOff /> {is_active === true ? 'Inactive' : 'Activate'}
                </Dropdown.Item>

                <Dropdown.Item
                  className={classes.drodownMenuButtons}
                  onClick={() => handleEditAction(id)}
                >
                  <FaRegPenToSquare /> Edit
                </Dropdown.Item>

                <Dropdown.Item
                  className={classes.drodownMenuButtons}
                  onClick={() => handleDeleteAction(id)}
                >
                  <MdDataUsage /> Delete
                </Dropdown.Item>
              </>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    )
  })

  return (
    <div className={classes.members}>
      <UniversalHiddenScreen
        visible={visibleBackground}
        handleVisibility={handleVisibility}
        contentBlock={<InviteColleague handleVisibility={handleVisibility} />}
      />

      <BrandVoiceTrainingModal
        showTrainingModal={showTrainingModal}
        setShowTrainingModal={setShowTrainingModal}
        refetch={refetch}
        data={currentId && actionType === ActionType.EDIT_BRAND_VOICE ? selectedBrandVoice : null}
      />

      <ConfirmationModal
        header='Warning'
        message={confirmationMessage}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        show={
          currentId !== null &&
          actionType !== ActionType.EDIT_BRAND_VOICE &&
          actionType !== ActionType.ADD_BRAND_VOICE
        }
      />

      <div className='w-100'>
        <div className='row'>
          <div className='col-lg-4'>
            <h3 className={classes.header}>Brand Voice</h3>
            <p className={classes.subHeader}>Create Different Brand Voice for Workflows</p>
          </div>
          <div className='col-lg-8'>
            <div
              className={userClasses.members__topBlock__btnsBlock}
              style={{flexDirection: 'row-reverse'}}
            >
              <button onClick={() => handleAddAction()} title='Feed your personalities'>
                <BsPersonAdd size={24} /> Add Brand Voice
              </button>

              <InputGroup
                style={{
                  width: 'unset',
                  border: '1px solid #282828',
                  background: 'white',
                  color: '#282828',
                  borderRadius: '4px',
                }}
              >
                <Form.Control
                  placeholder='Enter your keywords'
                  aria-label='Enter your keywords'
                  aria-describedby='search-input'
                  onChange={(e) => {
                    setKeywords(e.target.value)
                  }}
                />
              </InputGroup>
            </div>
          </div>
        </div>
      </div>

      <div className='w-100'>
        <Table
          responsive
          style={{
            borderCollapse: 'collapse',
            borderSpacing: '0',
            borderBottom: '1px solid #fff',
            minHeight: '200px',
          }}
        >
          <thead>
            <tr>
              <th style={{width: '20%'}}>Name</th>
              <th style={{width: '50%'}}>Traits</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </Table>
        <tfoot
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '22px',
            width: '100%',

          }}
          className='mt-3 mt-md-0'
        >
          <tr>
            <td colSpan={7}>
              <nav className='w-100 bg-light  d-flex justify-content-center p-3'>
                <ul className='pagination pagination-lg'>
                  <li className='page-item' onClick={() => handlePagination('prev')}>
                    <button className='page-link' disabled={page === 1}>
                      <span aria-hidden='true'>&laquo;</span>
                    </button>
                  </li>
                  <li className='page-item'>
                    <button className='page-link'>{page}</button>
                  </li>
                  <li className='page-item' onClick={() => handlePagination('next')}>
                    <button
                      className='page-link'
                      disabled={!brandVoices?.brandVoices?.hasMoreMembers}
                    >
                      <span aria-hidden='true'>&raquo;</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </td>
          </tr>
        </tfoot>
      </div>
    </div>
  )
}

export default BrandVoice
