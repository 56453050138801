import {useState} from 'react'
import {useAuth} from '../../../../../../app/modules/auth'
import {TopUpPlansDataLocal, TopUpPlansDataProduction} from '../data/TopUpPlansData'
import {useGetTopupsQuery} from '../../../../../../services/settingServiceApi'
import axios from 'axios'
import {toast} from 'react-toastify'

interface PlanOption {
  name: string // Tier name (e.g., 'Tier 1', 'Tier 2')
  full_name: string
  value: number // Number of words or images
  label: string // Full description (e.g., '100,000 words + 1000 credits - $50')
  payment_link: string
}

interface SelectedPlans {
  words_and_credits: PlanOption
  images: PlanOption
}

const TopUpComponent: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedPlans, setSelectedPlans] = useState<SelectedPlans>({
    words_and_credits:
      process.env.REACT_APP_ENV === 'production'
        ? TopUpPlansDataProduction.words_and_credits[0]
        : TopUpPlansDataLocal.words_and_credits[0],
    images:
      process.env.REACT_APP_ENV === 'production'
        ? TopUpPlansDataProduction.images[0]
        : TopUpPlansDataLocal.images[0],
  })
  const {currentUser} = useAuth()

  const handlePlanSelect = (category: keyof SelectedPlans, selectedOption: PlanOption) => {
    setSelectedPlans((prevState) => ({
      ...prevState,
      [category]: selectedOption,
    }))
  }

  const handlePayment = async (url: string, tier_name: string) => {
    try {
      setIsLoading(true)
      const API_URL = process.env.REACT_APP_API_URL

      const isPurchasable = await axios.post(`${API_URL}/topup/is-purchasable`, {
        tier_name: tier_name,
      })

      if (!isPurchasable.data) {
        toast.error('You have already purchased this plan')
        return
      }

      if (currentUser?.email) {
        window.open(`${url}?prefilled_email=${encodeURIComponent(currentUser.email)}`, '_blank')
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const renderCard = (category: keyof SelectedPlans, data: PlanOption[]) => {
    const categoryLabel = category === 'words_and_credits' ? 'Words & Credits' : 'Images'

    return (
      <div className='col-12 col-md-4'>
        <div className='card h-100 shadow-sm'>
          <div className='card-body'>
            <div className='d-flex align-items-center mb-3'>
              <i
                className={`bi bi-${
                  category === 'words_and_credits' ? 'file-text' : 'image'
                } fs-4 me-2`}
              />
              <h5 className='card-title mb-0'>{categoryLabel} Package</h5>
            </div>
            {/* <p className='card-text text-muted'>
              {category === 'words_and_credits'
                ? 'Purchase words and credits'
                : 'Purchase images'}
            </p> */}
            <div className='form-group'>
              <label htmlFor={`${category}Select`} className='form-label'>
                Select Amount
              </label>
              <select
                className='form-select'
                id={`${category}Select`}
                onChange={(e) =>
                  handlePlanSelect(category, data.find((item) => item.name === e.target.value)!)
                }
                value={selectedPlans[category].name}
              >
                {data.map((option) => (
                  <option key={option.name} value={option.name}>
                    {option.name} - {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className='mt-3'>
              <span className='badge bg-secondary'>Selected Plan</span>
              {/* <p className="mt-2 mb-0">{selectedPlans[category].name}</p> */}
              <p className='mt-2 mb-0'>{selectedPlans[category].label}</p>{' '}
              {/* Shows the full label here */}
            </div>

            <div className='mt-3'>
              <button
                style={{width: 'unset'}}
                className='universalButton'
                onClick={() =>
                  handlePayment(
                    selectedPlans[category].payment_link,
                    selectedPlans[category].full_name
                  )
                }
                disabled={isLoading}
              >
                {isLoading ? (
                  <span
                    className='spinner-border spinner-border-sm'
                    role='status'
                    aria-hidden='true'
                  ></span>
                ) : (
                  'Purchase'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='row g-4'>
      {renderCard(
        'words_and_credits',
        process.env.REACT_APP_ENV === 'production'
          ? TopUpPlansDataProduction.words_and_credits
          : TopUpPlansDataLocal.words_and_credits
      )}
      {renderCard(
        'images',
        process.env.REACT_APP_ENV === 'production'
          ? TopUpPlansDataProduction.images
          : TopUpPlansDataLocal.images
      )}
    </div>
  )
}

export default TopUpComponent
