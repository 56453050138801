import {FC} from 'react'
import {Outlet} from 'react-router-dom'
import {IntercomProvider} from 'react-use-intercom'
import {useAuth} from '../../app/modules/auth'
import '../index.scss'
import Header from './Header'
import {CompanyName} from './company-name-page/CompanyName'
import DemoAppTour from './demo-app-tour/DemoAppTour'
import {isRunningOnKlevereDomain} from '../../utils/white-label/permission'

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID
  ? process.env.REACT_APP_INTERCOM_APP_ID
  : ''

const Layout: FC = () => {
  const {currentUser} = useAuth()
  return (
    <div className='app'>
      {currentUser?.company_name.trim() === '' ? (
        <CompanyName />
      ) : (
        <>
          <Header />
          {isRunningOnKlevereDomain() ? (
            <IntercomProvider
              appId={INTERCOM_APP_ID}
              autoBoot
              autoBootProps={{name: currentUser?.first_name, email: currentUser?.email}}
            >
              <div className='main'>
                <Outlet />
              </div>
            </IntercomProvider>
          ) : (
            <div className='main'>
              <Outlet />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default Layout
