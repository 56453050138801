import {createRoot} from 'react-dom/client'
import {Provider} from 'react-redux'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {GoogleOAuthProvider} from '@react-oauth/google'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
// Apps
// import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
// import './_metronic/assets/fonticon/fonticon.css'
// import './_metronic/assets/keenicons/duotone/style.css'
// import './_metronic/assets/keenicons/outline/style.css'
// import './_metronic/assets/keenicons/solid/style.css'
// import './_metronic/assets/customcss/chatbox.css'
// import './_metronic/assets/customcss/task.css'
// import './_metronic/assets/customcss/all.css'
// import './_metronic/assets/customcss/editoroftask.css'
// import 'react-tooltip/dist/react-tooltip.css'

// import './new-design/index.scss'

/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
// import './_metronic/assets/sass/style.scss'
// import './_metronic/assets/sass/plugins.scss'
// import './_metronic/assets/sass/style.react.scss'
// import './_metronic/assets/sass/content-styles.css'

// import './assets/styles/base/_base.scss'
// import './assets/styles/base/_breakpoints.scss'

// import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
import {store} from './store/store'
import MyRoutes from './new-design/router/MyRoutes'
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')
const GOOGLE_OAUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID

if (container) {
  createRoot(container).render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <GoogleOAuthProvider clientId={`${GOOGLE_OAUTH_CLIENT_ID}`}>
            <MyRoutes />
          </GoogleOAuthProvider>
        </AuthProvider>
      </QueryClientProvider>
    </Provider>
  )
}
