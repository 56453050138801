import {type FC, useEffect, useState} from 'react'
import classes from './ImagesArea.module.scss'
import ImageCard from './ImageCard'
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry'
import {Spinner, Tab, Tabs} from 'react-bootstrap'
import CategoryIcon from '../../icons/images/image-generation-form/CategoryIcon'
import MoreSquareIcon from '../../icons/images/image-generation-form/MoreSquareIcon'
import GalleryIcon from '../../icons/images/image-generation-form/GalleryIcon'
import RefreshIcon from '../../icons/images/image-generation-form/RefreshIcon'
import BrushIcon from '../../icons/images/image-generation-form/BrushIcon'
import Cube3dIcon from '../../icons/images/image-generation-form/Cube3dIcon'
import {ImageModel} from '../models/ImageModel'
import {useGetGeneratedImagesMutation} from '../../../../services/imageGenerationService'
import React from 'react'
import {imagesData} from '../data/images'
import {shuffle} from 'lodash'
import ClipBoardIcon from '../../icons/images/image-generation-form/ClipBoardIcon'

interface ImagesAreaProps {
  handleOnclickImage: Function
  isGeneratedAt: number
}

const ImagesArea: FC<ImagesAreaProps> = ({handleOnclickImage, isGeneratedAt}) => {
  const [images, setImages] = useState<ImageModel[]>(imagesData)

  const [selectedTabKey, setSelectedTabKey] = useState<string>('all')
  const [setAllImages, setAllImagesState] = useState<ImageModel[]>([])
  const [getGeneratedImages, {data, error, isLoading}] = useGetGeneratedImagesMutation()
  const [page, setPage] = useState(1)

  const getImages = async () => {
    try {
      const response = await getGeneratedImages({page}).unwrap()

      if (response.images.length >= 15) {
        setImages(response.images)
        setAllImagesState(response.images)
      } else {
        const combinedImages = [
          ...response.images,
          ...imagesData.slice(0, 15 - response.images.length),
        ]

        setImages(combinedImages)
        setAllImagesState(combinedImages)
      }
    } catch (error) {
      console.error('Error fetching images:', error)
    }
  }

  const handleLoadMore = async () => {
    setPage(page + 1)
    await getImages()
  }

  useEffect(() => {
    getImages()
  }, [])

  useEffect(() => {
    if (isGeneratedAt) {
      getImages()
    }
  }, [isGeneratedAt])

  return (
    <div className='border rounded-4 px-3 py-4'>
      <div className='d-flex gap-3 flex-wrap justify-content-between'>
        <h3 className={classes.topBlock__title}>Explore</h3>
        {/* <div  aria-label="Refresh images"  onClick={getImages} style={{cursor: 'pointer'}}>
          <RefreshIcon />
        </div> */}
      </div>

      {isLoading ? (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='border' role='status' />
        </div>
      ) : (
        <Tabs
          activeKey={selectedTabKey}
          onSelect={(k) => setSelectedTabKey(k as string)}
          className={`mt-4 mb-4 ${classes.tabs}`}
        >
          <Tab
            eventKey='all'
            title={
              <div
                className={`d-flex align-items-center gap-2 ${
                  selectedTabKey === 'all'
                    ? classes.tabs__tabItemContent__selected
                    : classes.tabs__tabItemContent
                }`}
              >
                <CategoryIcon color={selectedTabKey === 'all' ? 'var(--primary-color)' : undefined} />
                <h3 className={classes.tabs__tabItemContent__title}>All</h3>
              </div>
            }
          >
            <ResponsiveMasonry
              columnsCountBreakPoints={{350: 1, 750: 2, 900: 3, 1100: 4}}
              className='flex-start'
            >
              <Masonry gutter='1rem'>
                {images.map((image, imageIdx) => (
                  <ImageCard image={image} key={imageIdx} handleOnclickImage={handleOnclickImage} />
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </Tab>
          <Tab
            eventKey='general'
            title={
              <div
                className={`d-flex align-items-center gap-2 ${
                  selectedTabKey === 'general'
                    ? classes.tabs__tabItemContent__selected
                    : classes.tabs__tabItemContent
                }`}
              >
                <MoreSquareIcon color={selectedTabKey === 'general' ? 'var(--primary-color)' : undefined} />
                <h3 className={classes.tabs__tabItemContent__title}>General</h3>
              </div>
            }
          >
            <ResponsiveMasonry
              columnsCountBreakPoints={{350: 1, 750: 2, 900: 3, 1100: 4}}
              className='flex-start'
            >
              <Masonry gutter='1rem'>
                {images
                  .filter((image) => image.type === 'GENERAL') // Filter for type "GENERAL"
                  .map((image, imageIdx) => (
                    <ImageCard
                      image={image}
                      key={imageIdx}
                      handleOnclickImage={handleOnclickImage}
                    />
                  ))}
              </Masonry>
            </ResponsiveMasonry>
          </Tab>
          <Tab
            eventKey='realistic'
            title={
              <div
                className={`d-flex align-items-center gap-2 ${
                  selectedTabKey === 'realistic'
                    ? classes.tabs__tabItemContent__selected
                    : classes.tabs__tabItemContent
                }`}
              >
                <GalleryIcon color={selectedTabKey === 'realistic' ?'var(--primary-color)' : undefined} />
                <h3 className={classes.tabs__tabItemContent__title}>Realistic</h3>
              </div>
            }
          >
            <ResponsiveMasonry
              columnsCountBreakPoints={{350: 1, 750: 2, 900: 3, 1100: 4}}
              className='flex-start'
            >
              <Masonry gutter='1rem'>
                {images
                  .filter((image) => image.type === 'REALISTIC') // Filter for type "GENERAL"
                  .map((image, imageIdx) => (
                    <ImageCard
                      image={image}
                      key={imageIdx}
                      handleOnclickImage={handleOnclickImage}
                    />
                  ))}
              </Masonry>
            </ResponsiveMasonry>
          </Tab>
          <Tab
            eventKey='design'
            title={
              <div
                className={`d-flex align-items-center gap-2 ${
                  selectedTabKey === 'design'
                    ? classes.tabs__tabItemContent__selected
                    : classes.tabs__tabItemContent
                }`}
              >
                <BrushIcon color={selectedTabKey === 'design' ? 'var(--primary-color)' : undefined} />
                <h3 className={classes.tabs__tabItemContent__title}>Design</h3>
              </div>
            }
          >
            <ResponsiveMasonry
              columnsCountBreakPoints={{350: 1, 750: 2, 900: 3, 1100: 4}}
              className='flex-start'
            >
              <Masonry gutter='1rem'>
                {images
                  .filter((image) => image.type === 'DESIGN') // Filter for type "GENERAL"
                  .map((image, imageIdx) => (
                    <ImageCard
                      image={image}
                      key={imageIdx}
                      handleOnclickImage={handleOnclickImage}
                    />
                  ))}
              </Masonry>
            </ResponsiveMasonry>
          </Tab>
          <Tab
            eventKey='3d'
            title={
              <div
                className={`d-flex align-items-center gap-2 ${
                  selectedTabKey === '3d'
                    ? classes.tabs__tabItemContent__selected
                    : classes.tabs__tabItemContent
                }`}
              >
                <Cube3dIcon color={selectedTabKey === '3d' ? 'var(--primary-color)' : undefined} />
                <h3 className={classes.tabs__tabItemContent__title}>3D</h3>
              </div>
            }
          >
            <ResponsiveMasonry
              columnsCountBreakPoints={{350: 1, 750: 2, 900: 3, 1100: 4}}
              className='flex-start'
            >
              <Masonry gutter='1rem'>
                {images
                  .filter((image) => image.type === 'RENDER_3D') // Filter for type "GENERAL"
                  .map((image, imageIdx) => (
                    <ImageCard
                      image={image}
                      key={imageIdx}
                      handleOnclickImage={handleOnclickImage}
                    />
                  ))}
              </Masonry>
            </ResponsiveMasonry>
          </Tab>
          <Tab
            eventKey='anime'
            title={
              <div
                className={`d-flex align-items-center gap-2 ${
                  selectedTabKey === 'anime'
                    ? classes.tabs__tabItemContent__selected
                    : classes.tabs__tabItemContent
                }`}
              >
                <ClipBoardIcon color={selectedTabKey === 'anime' ? 'var(--primary-color)' : undefined} />
                <h3 className={classes.tabs__tabItemContent__title}>Anime</h3>
              </div>
            }
          >
            <ResponsiveMasonry
              columnsCountBreakPoints={{350: 1, 750: 2, 900: 3, 1100: 4}}
              className='flex-start'
            >
              <Masonry gutter='1rem'>
                {images
                  .filter((image) => image.type === 'ANIME') // Filter for type "GENERAL"
                  .map((image, imageIdx) => (
                    <ImageCard
                      image={image}
                      key={imageIdx}
                      handleOnclickImage={handleOnclickImage}
                    />
                  ))}
              </Masonry>
            </ResponsiveMasonry>
          </Tab>
        </Tabs>
      )}

      {/* <button onClick={handleLoadMore}> + More</button> */}
    </div>
  )
}

export default React.memo(ImagesArea)
