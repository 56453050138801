import React, {useEffect, useState} from 'react'
import {Button, Form, Row, Col, OverlayTrigger, Tooltip, Spinner} from 'react-bootstrap'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import {
  useGetPlanQuery,
  useGetPlansQuery,
  usePlanUpdateOrCreateMutation,
} from '../../../../../../services/settingServiceApi'
import {GlobalErrorMessagesEnum} from '../../../../../../types/types'
import {toast} from 'react-toastify'

import '/node_modules/flag-icons/css/flag-icons.min.css'
import {currencyOptions} from '../data/currencyOptions'
import {useLocation} from 'react-router-dom'
import {CustomTooltip} from '../../../../universal-components/tooltip/Tooltip'
import classes from './../Resellers.module.scss'
import {catchHandler} from '../../../../../../utils/http/catchHandler'
import SubmitBtn from './global/submitBtn'
import {useAuth} from '../../../../../../app/modules/auth'
import {useGetPanInfoQuery} from '../../../../../../services/companyServiceApi'
import {useGetBalanceQuery} from '../../../../../../services/balanceServiceApi'

interface FormValues {
  name: string
  is_white_label: boolean
  user_id: string
  active: boolean
  amount: number
  words: number
  images: number
  users: number
  credits: number
  currency: string
  interval: string
  interval_count: number
  trial_days: number
  public_id?: string | null
}

const validationSchema = Yup.object({
  name: Yup.string()
    .min(3, 'Must be at least 3 characters')
    .max(100, 'Max 100 characters')
    .required('Required'),
  is_white_label: Yup.boolean().required(),

  active: Yup.boolean().required(),
  amount: Yup.number().min(1, 'Cannot be negative').required('Required'),
  words: Yup.number().min(0, 'Cannot be negative').required(),
  images: Yup.number().min(0, 'Cannot be negative').required(),
  users: Yup.number().min(1, 'Cannot be negative').required(),
  credits: Yup.number().min(0, 'Cannot be negative').required(),
  currency: Yup.string().length(3, 'Must be 3 characters').required(),
  interval: Yup.string()
    .max(5, 'Max 5 characters')
    .required()
    .oneOf(['month', 'year'] as const),

  trial_days: Yup.number().min(0, 'Cannot be negative').required(),
})

const PackageComponent: React.FC = () => {
  const location = useLocation()
  const publicId = new URLSearchParams(location.hash.split('?')[1]).get('public_id') || null

  const {data: customPlans} = useGetPlansQuery({page: 1, limit: 9999999999})
  const [submit, {isLoading}] = usePlanUpdateOrCreateMutation()

  const {data: plan} = useGetPlanQuery({publicId: publicId}, {skip: !publicId})
  const {currentUser} = useAuth()
  const {data: planData} = useGetPanInfoQuery(currentUser?.id)
  const {data: balance} = useGetBalanceQuery({userId: currentUser?.id})

  const [unusedLimit, setUnusedLimit] = useState<any>({images: 0, words: 0, credits: 0})

  useEffect(() => {
    if (plan) {
      formik.setValues({
        name: plan.name || '',
        is_white_label: plan.is_white_label || false,
        user_id: plan.user_id || '0',
        active: plan.active || false,
        amount: plan.amount || 1,
        words: plan.words || 1,
        images: plan.images || 1,
        users: plan.users || 1,
        credits: plan.credits || 500,
        currency: plan.currency || '',
        interval: plan.interval || '',
        interval_count: plan.interval_count || 1,
        trial_days: plan.trial_days || 0,
        public_id: plan.public_id,
      })
    }
  }, [plan])

  // Make sure user has enough credits,words,images to create this plan
  // We will check his secondary balance + his primary balance
  // Let's merge them, then we can compare with current usage
  // We need check allocated plans
  // Current used up by Reseller.
  // Suppose, he has a limit of 100 words then he used 85, but he hadn't created any plan, so it will like he can create plan, because his plan is not allocated yet.
  // So,we need check both allocated custom plans and also, his current usage, against his primary and secondary balance.

  useEffect(() => {
    const calculateUnusedLimit = () => {
      if (!planData || !balance) return

      const {usageLimit = {}, usage} = planData
      const {left = {}} = balance

      // Here, we limit of which users has.
      const images = (usageLimit.images || 0) + (left.images || 0)

      const words = (usageLimit.words || 0) + (left.words || 0)
      const credits = (usageLimit.credits || 0) + (left.credits || 0)

      // Now get the used up limit from custom plans.
      const {usedUpImages, usedUpWords, usedUpCredits} = customPlans?.data?.reduce(
        (acc: {usedUpImages: number; usedUpWords: number; usedUpCredits: number}, plan: any) => {
          acc.usedUpImages += plan.images
          acc.usedUpWords += plan.words
          acc.usedUpCredits += plan.credits
          return acc
        },
        {usedUpImages: 0, usedUpWords: 0, usedUpCredits: 0}
      ) || {usedUpImages: 0, usedUpWords: 0, usedUpCredits: 0}

      const unusedImages = images - usedUpImages - usage.images
      const unusedWords = words - usedUpWords - usage.words
      const unusedCredits = credits - usedUpCredits - usage.credits

      setUnusedLimit({images: unusedImages, words: unusedWords, credits: unusedCredits})
    }

    calculateUnusedLimit()
  }, [planData, balance, customPlans])

  const formik = useFormik<FormValues>({
    initialValues: {
      name: '',
      is_white_label: false,
      user_id: '0',
      active: false,
      amount: 1,
      words: 0,
      images: 1,
      users: 1,
      credits: 1,
      currency: '',
      interval: '',
      interval_count: 1,
      trial_days: 0,
      public_id: publicId,
    },

    validationSchema,
    onSubmit: async (values) => {
      if (
        unusedLimit.images < values.images ||
        unusedLimit.words < values.words ||
        unusedLimit.credits < values.credits
      ) {
        toast.error("You don't have enough balance to create this plan.")
        return
      }

      submit(values)
        .unwrap()
        .then((res) => {
          if (publicId) {
            toast.success('Plan updated successfully')
          } else {
            toast.success('Plan created successfully')
            formik.resetForm()
          }
        })
        .catch((e) => {
          catchHandler(e)
        })
    },
  })

  return (
    <Form onSubmit={formik.handleSubmit} className={classes.form}>
      <div style={{textAlign: 'center'}}>
        <h5>Unused Limit:</h5>
        <div style={{display: 'flex', justifyContent: 'center', gap: '20px'}}>
          <div
            style={{
              padding: '10px 20px',
              backgroundColor: '#f8f9fa',
              border: '1px solid #ddd',
              borderRadius: '8px',
              textAlign: 'center',
              minWidth: '120px',
            }}
          >
            <p>Images</p>
            <p>{unusedLimit.images}</p>
          </div>
          <div
            style={{
              padding: '10px 20px',
              backgroundColor: '#f8f9fa',
              border: '1px solid #ddd',
              borderRadius: '8px',
              textAlign: 'center',
              minWidth: '120px',
            }}
          >
            <p>Words</p>
            <p>{unusedLimit.words}</p>
          </div>
          <div
            style={{
              padding: '10px 20px',
              backgroundColor: '#f8f9fa',
              border: '1px solid #ddd',
              borderRadius: '8px',
              textAlign: 'center',
              minWidth: '120px',
            }}
          >
            <p>Credits</p>
            <p>{unusedLimit.credits}</p>
          </div>
        </div>
      </div>

      <Row className='mt-5'>
        <Col md={3}>
          <Form.Group controlId='name' className='mb-3'>
            <Form.Label className={classes.form__label}>
              Name <span style={{color: 'red'}}>*</span>
              <CustomTooltip tooltipText='Name of the subscription plan' tooltipAnchor='formName' />
            </Form.Label>
            <Form.Control
              type='text'
              className={classes.form__input}
              placeholder='Enter plan name'
              {...formik.getFieldProps('name')}
              isInvalid={!!formik.errors.name && formik.touched.name}
            />
            <Form.Control.Feedback type='invalid'>{formik.errors.name}</Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col md={3}>
          <Form.Group controlId='amount' className='mb-3'>
            <Form.Label>
              Amount <span style={{color: 'red'}}>*</span>
              <CustomTooltip
                tooltipText='Subscription amount in currency'
                tooltipAnchor='formName'
              />
            </Form.Label>
            <Form.Control
              type='number'
              placeholder='Enter amount'
              {...formik.getFieldProps('amount')}
              isInvalid={!!formik.errors.amount && formik.touched.amount}
            />
            <Form.Control.Feedback type='invalid'>{formik.errors.amount}</Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col md={3}>
          <Form.Group controlId='currency' className='mb-3'>
            <Form.Label>
              Currency <span style={{color: 'red'}}>*</span>
              <CustomTooltip
                tooltipText='3-letter currency code (e.g., USD)'
                tooltipAnchor='formName'
              />
            </Form.Label>
            <Form.Select
              {...formik.getFieldProps('currency')}
              isInvalid={!!formik.errors.currency && formik.touched.currency}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            >
              <option value=''>Select currency</option>
              {currencyOptions.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.name} ({option.code})
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type='invalid'>{formik.errors.currency}</Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col md={3}>
          <Form.Group controlId='interval' className='mb-3'>
            <Form.Label>
              Interval <span style={{color: 'red'}}>*</span>
              <CustomTooltip
                tooltipText='Billing interval (e.g., month or year)'
                tooltipAnchor='formName'
              />
            </Form.Label>
            <Form.Select
              placeholder='Select interval'
              {...formik.getFieldProps('interval')}
              isInvalid={!!formik.errors.interval && formik.touched.interval}
            >
              <option value=''>Select interval</option> {/* Default option */}
              <option value='month'>Month</option>
              <option value='year'>Year</option>
            </Form.Select>
            <Form.Control.Feedback type='invalid'>{formik.errors.interval}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={3}>
          <Form.Group controlId='words' className='mb-3'>
            <Form.Label>
              Words <span style={{color: 'red'}}>*</span>
              <CustomTooltip tooltipText='Number of words included' tooltipAnchor='formName' />
            </Form.Label>
            <Form.Control
              type='number'
              placeholder='Enter words count'
              {...formik.getFieldProps('words')}
              isInvalid={!!formik.errors.words && formik.touched.words}
            />
            <Form.Control.Feedback type='invalid'>{formik.errors.words}</Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col md={3}>
          <Form.Group controlId='images' className='mb-3'>
            <Form.Label>
              Images <span style={{color: 'red'}}>*</span>
              <CustomTooltip tooltipText='Number of images included' tooltipAnchor='formName' />
            </Form.Label>
            <Form.Control
              type='number'
              placeholder='Enter images count'
              {...formik.getFieldProps('images')}
              isInvalid={!!formik.errors.images && formik.touched.images}
            />
            <Form.Control.Feedback type='invalid'>{formik.errors.images}</Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col md={3}>
          <Form.Group controlId='credits' className='mb-3'>
            <Form.Label>
              Credits <span style={{color: 'red'}}>*</span>
              <CustomTooltip tooltipText='Number of credits included' tooltipAnchor='formName' />
            </Form.Label>
            <Form.Control
              type='number'
              placeholder='Enter credits count'
              {...formik.getFieldProps('credits')}
              isInvalid={!!formik.errors.credits && formik.touched.credits}
            />
            <Form.Control.Feedback type='invalid'>{formik.errors.credits}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={3}>
          <Form.Group controlId='users' className='mb-3'>
            <Form.Label>
              Max Users <span style={{color: 'red'}}>*</span>
              <CustomTooltip
                tooltipText='Number of the users a company can have'
                tooltipAnchor='formName'
              />
            </Form.Label>
            <Form.Control
              type='number'
              placeholder='Enter number of the users a company can have'
              {...formik.getFieldProps('users')}
              isInvalid={!!formik.errors.users && formik.touched.users}
            />
            <Form.Control.Feedback type='invalid'>{formik.errors.users}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row hidden>
        <Col md={3}>
          <Form.Group controlId='interval_count' className='mb-3'>
            <Form.Label>
              Interval Count <span style={{color: 'red'}}>*</span>
              <CustomTooltip
                tooltipText='Number of intervals (e.g., 12 for monthly)'
                tooltipAnchor='formName'
              />
            </Form.Label>
            <Form.Control
              type='number'
              placeholder='Enter interval count'
              {...formik.getFieldProps('interval_count')}
              isInvalid={!!formik.errors.interval_count && formik.touched.interval_count}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.interval_count}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col md={3} hidden>
          <Form.Group controlId='trial_days' className='mb-3'>
            <Form.Label>
              Trial Days <span style={{color: 'red'}}>*</span>
            </Form.Label>
            <Form.Control
              type='number'
              placeholder='Enter trial days'
              {...formik.getFieldProps('trial_days')}
              isInvalid={!!formik.errors.trial_days && formik.touched.trial_days}
            />
            <Form.Control.Feedback type='invalid'>{formik.errors.trial_days}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={3} hidden>
          <Form.Check
            type='switch'
            label='White Label'
            checked={true} // Set it active by default
            disabled={true} // Make it disabled by default
            {...formik.getFieldProps('is_white_label')}
            isInvalid={!!formik.errors.is_white_label && formik.touched.is_white_label}
          />
        </Col>

        <Col md={3}>
          <label>
            Status{' '}
            <CustomTooltip
              tooltipText='Whether the plan is active or not'
              tooltipAnchor='formName'
            />
          </label>
          <Form.Check
            size={100}
            type='switch'
            checked={formik.values.active}
            label='Active'
            {...formik.getFieldProps('active')}
            isInvalid={!!formik.errors.active && formik.touched.active}
            className='form-control-lg'
          />
        </Col>
      </Row>

      <SubmitBtn isLoading={isLoading} />
    </Form>
  )
}

export default PackageComponent
