import classes from './AuthStyles.module.scss'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {useGoogleLogin} from '@react-oauth/google'
import {
  googleAuthentication,
  emailToken,
  queryParameters,
} from '../../../app/modules/auth/core/_requests'
import {useAuth} from '../../../app/modules/auth/core/Auth'
import {
  useConfirmEmailMutation,
  useLazyGetUserByTokenQuery,
  useLoginMutation,
} from '../../../services/authServiceApi'
import GoogleIcon from '../icons/inviteform/GoogleIcon'
import loginFormData from '../../data/auth/loginFormData'
import {useEffect, useState} from 'react'
import UniversalHiddenScreen from '../universal-components/hidden-screen/UniversalHiddenScreen'
import {VerifyEmail} from './VerifyEmail'
import {toast} from 'react-toastify'
import {FiEye, FiEyeOff} from 'react-icons/fi'
import authorizedBrowserExtension from '../../../utils/browserExtension/authorizedBrowserExtension'
import {isRunningOnKlevereDomain} from '../../../utils/white-label/permission'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required')
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Please enter a valid email address'),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const {saveAuth, setCurrentUser} = useAuth()
  const [login, {isLoading, isError}] = useLoginMutation()
  const [confirmEmail] = useConfirmEmailMutation()
  const [getUserByToken] = useLazyGetUserByTokenQuery()
  const [visibleBackground, setVisibleBackground] = useState<boolean>(
    localStorage.getItem('verify_email') === 'true'
  )
  const [showPassword, setShowPassword] = useState(false)
  const appsumocode = sessionStorage.getItem('appsumocode') || null
  const redirectUri = queryParameters.get('redirect_uri') || null

  useEffect(() => {
    if (emailToken && emailToken !== null) {
      confirmEmail(emailToken)
      toast.success('E-mail verified successfully')
    }
    localStorage.removeItem('verify_email')
    const uuid = queryParameters.get('uuid')
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        const payload = await login({
          email: values.email,
          password: values.password,
          code: appsumocode,
        }).unwrap()
        saveAuth(payload)
        const user = await getUserByToken(payload.access_token).unwrap()
        setCurrentUser(user)

        // Authorize browser extension
        const chromeExtensionPrefix = 'chrome-extension://'
        if (redirectUri && redirectUri.startsWith(chromeExtensionPrefix)) {
          authorizedBrowserExtension(user, redirectUri)
        }
      } catch (err: any) {
        toast.error(err.data.message)
        setSubmitting(false)
      }
      sessionStorage.removeItem('appsumocode')
    },
  })

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) =>
      googleAuthentication(codeResponse, appsumocode).then(async (res) => {
        if (codeResponse.access_token) {
          saveAuth(res?.data)
          if (res?.data.access_token) {
            const user = await getUserByToken(res?.data.access_token).unwrap()
            setCurrentUser(user)
          }
        }
      }),
    onError: (error) => console.log('Login from Google Failed', error),
  })

  const handleVisibility = () => {
    setVisibleBackground((prev) => !prev)
  }

  const inputsBlock = loginFormData.map((item) => {
    const {id, title, inputType, name, placeholder, autocomlete, required} = item

    const togglePasswordVisibility = () => {
      setShowPassword((prevState) => !prevState)
    }

    return (
      <label key={id} className={classes.auth__form__label}>
        <h4 className={classes.auth__form__label__title}>{title}</h4>
        <input
          type={inputType === 'password' && showPassword ? 'text' : inputType || 'text'}
          placeholder={placeholder}
          required={required}
          {...formik.getFieldProps(name)}
          autoComplete={autocomlete}
        />
        {name === 'password' && (
          <span
            style={{
              position: 'absolute',
              top: '70%',
              right: '8px',
              transform: 'translateY(-50%)',
              cursor: 'pointer',
            }}
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <FiEyeOff size={16} /> : <FiEye size={16} />}
          </span>
        )}

        {formik.touched[name as keyof typeof formik.touched] &&
          formik.errors[name as keyof typeof formik.errors] && (
            <span
              style={{position: 'absolute', top: '100%', marginTop: '10px'}}
              className={classes.messageError}
              role='alert'
            >
              {formik.errors[name as keyof typeof formik.errors]}
            </span>
          )}
      </label>
    )
  })

  return (
    <div className='appAuth'>
      <div className={classes.auth}>
        <UniversalHiddenScreen
          visible={visibleBackground}
          handleVisibility={handleVisibility}
          contentBlock={<VerifyEmail />}
        />
        <div className={classes.auth__topBlock}>
          <h1 className={classes.auth__topBlock__title}>Sign in</h1>
          {isRunningOnKlevereDomain() && (
            <>
              <button className={classes.auth__topBlock__googleBtn} onClick={() => googleLogin()}>
                <GoogleIcon className={classes.auth__topBlock__googleBtn__svg} />
                Continue with Google
              </button>
              <div className={classes.auth__topBlock__separator}>
                <span>or</span>
              </div>
            </>
          )}
        </div>

        {isError ? (
          <span className={classes.messageError}>
            {/* The login details are incorrect! Please try again */}
          </span>
        ) : null}

        <form className={classes.auth__form} onSubmit={formik.handleSubmit} noValidate>
          {inputsBlock}

          {isRunningOnKlevereDomain() && (
            <div className={classes.auth__form__linkBlock}>
              <Link to='/auth/forgot-password'>Forgot Password?</Link>
            </div>
          )}

          {
            isRunningOnKlevereDomain() ? (
              <button
            type='submit'
            className='universalButton'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {isLoading ? <>Please wait...</> : <>Continue</>}
          </button>
            ) : (
              <button
            type='submit'
            className='universalButton'
            disabled={formik.isSubmitting || !formik.isValid}
            style={{ 
              background: '#5dc5ea',
              color: 'white',
               
             }}
          >
            {isLoading ? <>Please wait...</> : <>Continue</>}
          </button>
            )
          }
        </form>
        <div className={classes.auth__footer}>
          
          {isRunningOnKlevereDomain() && (

<>
<span className={classes.auth__signUp}>
            Not a Member yet?
            <Link to='/auth/registration'>Sign up</Link>
          </span>

            <p>
              Email <span className={classes.auth__footer__email}>support@klevere.ai</span> or chat
              with us to report any issues
            </p>
</>
          )}
        </div>
      </div>
    </div>
  )
}
