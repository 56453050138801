import {getCurrentDomainName} from '../http/domain'

export enum SidebarPermission {
  HOME = 'Home',
  MARKETING = 'Marketing',
  SALES = 'Sales',
  HUMAN_RESOURCE = 'Human Resource',
  FINANCE = 'Finance',
  REAL_ESTATE = 'Real Estate',
  CHAT = 'Chat',
  IMAGES = 'Images',
  RECENT_ACTIVITY = 'Recent Activity',
  SETTINGS = 'Settings',
}

export enum Domain {
  KLEVERE_AI = 'app.klevere.ai',
  LOCAL_IP = '127.0.0.1',
  LOCAL_IP_ON_NETWORK = '10.0.0.101',
  PRODUCTION_IP = '35.156.184.218',
}

const domainPermissionsMap: Record<Domain, SidebarPermission[]> = {
  [Domain.KLEVERE_AI]: [
    SidebarPermission.HOME,
    SidebarPermission.MARKETING,
    SidebarPermission.SALES,
    SidebarPermission.HUMAN_RESOURCE,
    SidebarPermission.IMAGES,
    SidebarPermission.RECENT_ACTIVITY,
    SidebarPermission.SETTINGS,
    SidebarPermission.CHAT,
    SidebarPermission.FINANCE,
    SidebarPermission.REAL_ESTATE,
  ],
  [Domain.LOCAL_IP]: [
    SidebarPermission.HOME,
    SidebarPermission.MARKETING,
    SidebarPermission.SALES,
    SidebarPermission.HUMAN_RESOURCE,
    SidebarPermission.IMAGES,
    SidebarPermission.RECENT_ACTIVITY,
    SidebarPermission.SETTINGS,
    SidebarPermission.CHAT,
    SidebarPermission.FINANCE,
    SidebarPermission.REAL_ESTATE,
  ],
  [Domain.LOCAL_IP_ON_NETWORK]: [
    SidebarPermission.HOME,
    SidebarPermission.MARKETING,
    SidebarPermission.SALES,
    SidebarPermission.HUMAN_RESOURCE,
    SidebarPermission.IMAGES,
    SidebarPermission.RECENT_ACTIVITY,
    SidebarPermission.SETTINGS,
    SidebarPermission.CHAT,
    SidebarPermission.FINANCE,
    SidebarPermission.REAL_ESTATE,
  ],
  [Domain.PRODUCTION_IP]: [
    SidebarPermission.HOME,
    SidebarPermission.MARKETING,
    SidebarPermission.RECENT_ACTIVITY,
    SidebarPermission.IMAGES,
  ],
}

export const hasPermission = (domain: string, permission: SidebarPermission): boolean => {
  const permittedPermissions = domainPermissionsMap[domain as Domain] || []
  return permittedPermissions.includes(permission)
}

export const isRunningOnKlevereDomain = () => {
  return getCurrentDomainName() === Domain.KLEVERE_AI || getCurrentDomainName() === Domain.LOCAL_IP
}
