import {type FC, useEffect, useRef, useState} from 'react'
import classes from './BulkRun.module.scss'
import RunIcon from '../../icons/workflow/RunIcon'
import BulkRunForm from './BulkRunForm'
import ExportCSVBtn from './ExportCSVBtn'
import ImportCSVBtn from './ImportCSVBtn'
import {useCheckGenerateLimitMutation} from '../../../../services/gptServiceApi'
import {useAuth} from '../../../../app/modules/auth'
import {LimitNotification} from '../limit-notification/LimitNotification'
import {UniversalNotification} from '../universal-notification/UniversalNotification'
import {toast} from 'react-toastify'
import ImportCSVHuman from '../../hr-page/bulk-run-page/ImportCSVHuman'
import {Button} from 'react-bootstrap'
import axios from 'axios'
import {BsPlus} from 'react-icons/bs'
import ImportPDFBtn from './ImportPDFBtn'
import BulkFileImportBtn from './BulkFileImportBtn'
import FilterComponent from './components/FilterComponent'
import { useAppDispatch, useAppSelector } from '../../../../store/store'
import { isRunningOnKlevereDomain } from '../../../../utils/white-label/permission'

interface BulkRunProps {
  title: string
  templateItem: any
  uploadCvs?: boolean
}

const BulkRun: FC<BulkRunProps> = ({title = '', templateItem, uploadCvs}) => {
  const {auth} = useAuth()
  const [rowsCount, setRowsCount] = useState<any[]>([0])
  const [inputsNames, setInputsNames] = useState<string[]>([])
  const [columnsOrder, setColumnsOrder] = useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  const myRef = useRef<HTMLDivElement | null>(null)
  const [triggerRegenerateText, setTriggerRegenerateText] = useState<number | string>('')
  const [triggerRegenerateImg, setTriggerRegenerateImg] = useState<number | string>('')
  const [checkGenerateLimit] = useCheckGenerateLimitMutation()
  const [error, setError] = useState('')
  const [notification, setNotification] = useState('')
  const [currentWorkflowRowsLimit, setCurrentWorkflowRowsLimit] = useState(0)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [modalShow, setModalShow] = useState(false)
  const [description, setDescription] = useState('')

  const urlParams = new URLSearchParams(window.location.search)
  const bulkRunUuidParam = urlParams.get('uuid')
  const bulkRunHasDataParam = urlParams.get('has-data') === 'true';

  const [isLoading, setIsLoading] = useState(false)
  const [fetchData, setFetchData] = useState([])
  const [mappingInputs, setMappingInputs] = useState([])

  const dispatch = useAppDispatch();
  const sortingDirection = useAppSelector((state) => state.bulkRun.sortingDirection); 
  
  // useEffect(() => {
  //   console.log('Current Sorting Direction:', sortingDirection)

  //   const reversedRowsArray = rowsCount.reverse();
  //   const reverseMappingInputs = [...mappingInputs].reverse() ;

  //   console.log('reverseMappingInputs',reverseMappingInputs)


  //   console.log('mappingInputs',mappingInputs)
  //   console.log('reversedRowsArray', reversedRowsArray)

  // },[sortingDirection])


  useEffect(() => {
    if (bulkRunHasDataParam && bulkRunUuidParam) {
      setIsLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/bulk-run/${bulkRunUuidParam}`)
        .then((res) => {
          if (res.data?.data && res.data.data.length > 0) {
            const data = res.data.data
            const rowsCount = res.data.data.length
            setRowsCount([...Array(rowsCount).keys()])

            setFetchData(res.data.data)
          } else {
            console.error('No questions found in response data')
          }
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [])

  const addFormRow = () => {
    const maximumAllowedRows = rowsCount.length + 1
    const isLimitExceeded = currentWorkflowRowsLimit < maximumAllowedRows

    if (isLimitExceeded) {
      toast.warning(maximumRowsToastWarning)
      return
    }
    setRowsCount((prev: any) => [...prev, Date.now()])
  }

  const addFileCSV = (fileData: any[]) => {
    if (!fileData || fileData.length === 0) return
    setRowsCount((prev: any[]) => {
      return [...prev, ...fileData]
    })
  }
  const deleteLast = () => {
    if (rowsCount.length > 1) {
      const newRow = rowsCount.filter((item, index) => index !== rowsCount.length - 1)
      setRowsCount(newRow)
    }
  }

  const maximumRowsToastWarning = () => (
    <div>
      <p>Maximum rowsCount for this workflow: {currentWorkflowRowsLimit}</p>
      <a className='text-primary' href='/upgrade-plan'>
        Click here to upgrade!
      </a>
    </div>
  )

  const checkLimits = async () => {
    const checkRows: any = await checkGenerateLimit({
      limits: {
        words: templateItem.limits.words,
        credits: templateItem.limits.credits ? templateItem.limits.credits : 0,
      },
      rows: 99999,
      token: auth?.access_token,
    })

    const maximumRows = checkRows.error.data.message.replace(/[^0-9]/g, '')
    setCurrentWorkflowRowsLimit(maximumRows)

    // setNotification(
    //   `Maximum rows for this workflow: ${maximumRows}` ||
    //     'An error occured'
    // )
  }

  const getInputsNames = () => {
    const newInputsNames: string[] = []
    let rowsCount = myRef?.current?.querySelectorAll('tr:not(:has(th))')
    const result = [].slice.call(rowsCount).map(function (row: HTMLTableRowElement) {
      const cells = row.querySelectorAll('td')
      return [].slice.call(cells).map((cell: HTMLTableCellElement) => {
        const textArea = cell.querySelector('textarea')
        const select = cell.querySelector('select')
        // const div = cell.querySelector('[class*=text]')
        textArea && newInputsNames.push(textArea.name)
        select && newInputsNames.push(select.name)
      })
    })
    setInputsNames(newInputsNames)
  }

  const handleRunAll = async () => {
    try {
      const checkRows: any = await checkGenerateLimit({
        limits: {
          words: templateItem.limits.words,
          credits: templateItem.limits.credits ? templateItem.limits.credits : 0,
        },
        rowsCount: rowsCount.length,
        token: auth?.access_token,
      })
      if (!checkRows.error) {
        // Resume from where it was left off, without starting from the beginning.
        if (activeIndex != null && activeIndex !== 0) {
          if (activeIndex === rowsCount.length) {
            setActiveIndex(0)
          } else {
            setActiveIndex(activeIndex + 1)
          }
        } else {
          setActiveIndex(0)
        }

        // setActiveIndex(0);
        //setTriggerRegenerateText(Date.now())
        return
      }
      toast.warning(checkRows.error.data.message || 'An error occured')
      //setError(checkRows.error.data.message || 'An error occured')
    } catch (err: any) {}
  }

  const handleDeleteRow = (index: number) => {
    const elementToRemove = document.querySelector(`[data-table-index='${index}']`)
    const elements = document.querySelectorAll('[data-table-index]')

    if (elements.length > 1) {

      if (elementToRemove) {
        elementToRemove.remove()
      }
    }
  }

  const tablesBlock = rowsCount.map((item: number | [], index: number) => {
    const {requiredQuestions, optionalSelect, additionalQuestions} = templateItem
    const ITEM_WIDTH = 350 // Set minimum width for column
    const BASE_WIDTH = ITEM_WIDTH*4 // We have 4 static columns

    const totalColumnWidth =
      (requiredQuestions.length + optionalSelect.length + additionalQuestions.length) * ITEM_WIDTH +
      BASE_WIDTH

    return (
      <div
        key={index}
        className={classes.bulk__table__item}
        data-table-index={index}
        style={{minWidth: `${totalColumnWidth}px`}}
      >
        {!isLoading && (
          <BulkRunForm
            rowIndex={index}
            fetchData={fetchData[index]}
            mappingInputs={mappingInputs[index]}
            templateItem={templateItem}
            triggerRegenerateText={triggerRegenerateText}
            triggerRegenerateImg={triggerRegenerateImg}
            csvInputs={item}
            columnsOrder={columnsOrder}
            inputsNames={inputsNames}
            index={index}
            setActiveIndex={setActiveIndex}
            activeIndex={activeIndex}
            handleDeleteRow={handleDeleteRow}
            description={description}
          />
        )}
      </div>
    )
  })

  /* Disable restricted routes */
  const disableImportCSVBtnForNonCSVWorkflows = () => {
    const restrictedRoutes = [
      'pdf-comparer',
      'media-caption',
      'visual-prod-description',
      'screen-custom',
    ]

    return restrictedRoutes.some((route) => window.location.pathname.includes(route))
  }

  useEffect(() => {
    checkLimits()
  }, [currentWorkflowRowsLimit])

  useEffect(() => {
    getInputsNames()
  }, [])

  const handleAddRow = () => {
    const maximumAllowedRows = rowsCount.length + 1
    const isLimitExceeded = currentWorkflowRowsLimit < maximumAllowedRows

    if (isLimitExceeded) {
      toast.warning(maximumRowsToastWarning)
      return
    }
    setRowsCount((prev) => [...prev, {id: Date.now()}])
  }

  return (
    <div className={`universalPage ${classes.bulk}`}>
      <div className='universalTopBlock'>
        <h1 className='universalTopTitle'>{title}</h1>
        <div className='universalBreadcrumbs'>
          <span>Bulk Run</span>
          <span className='universalBreadcrumbs__lastChild'>(beta)</span>
        </div>
      </div>

      {error.trim() !== '' && <LimitNotification errorMessage={error} />}
      {notification.trim() !== '' && (
        <UniversalNotification notificatonMessage={notification} upgrade={true} />
      )}

      <div className='w-100 d-flex flex-md-row flex-column gap-4 align-items-center justify-content-between'>
        <div className='d-flex align-items-center gap-3'>


          {/* <FilterComponent /> */}


          <Button
            className={`${classes.bulk__btnsBlock__runAll}`}
            onClick={handleRunAll}
            style={{
              ...(!isRunningOnKlevereDomain() && { backgroundColor: '#5dc5ea', color: '#fff' })
            }}
          >
            <RunIcon color='white' /> Run All
          </Button>

          {/* <Button
          className={`${classes.bulk__addBtn} border btn btn-danger`}
          onClick={() => {
            alert('Are you sure you want to stop all?')
            setStopExecution(true)
          }}
        >
          <RunIcon color='white' /> Stop All
        </Button> */}

          <Button
            onClick={handleAddRow}
            className={`${classes.bulk__btnsBlock__addBtn}`}
            style={{
              ...(!isRunningOnKlevereDomain() && { backgroundColor: '#5dc5ea', color: '#fff' })
            }}
          >
            <BsPlus size={24} /> New Row
          </Button>
        </div>

        <div className={`d-flex align-items-center gap-3`}>
          {disableImportCSVBtnForNonCSVWorkflows() && (
            <BulkFileImportBtn
              setMappingInputs={setMappingInputs}
              templateItem={templateItem}
              setRowsCount={setRowsCount}
            />
          )}

          {!disableImportCSVBtnForNonCSVWorkflows() && (
            <ImportCSVBtn
              currentWorkflowRowsLimit={currentWorkflowRowsLimit}
              myRef={myRef}
              setRowsCount={setRowsCount}
              setColumnsOrder={setColumnsOrder}
              setDescription={setDescription}
              description={description}
            />
          )}

          <ExportCSVBtn myRef={myRef} />
        </div>
      </div>

      <div ref={myRef} className={classes.bulk__table}>
        <div className={classes.bulk__table__cont}>{tablesBlock}</div>
      </div>
    </div>
  )
}

export default BulkRun
